import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { GoogleAdsConfig, GoogleAdsInterstitialConfig } from 'BaxterScript/types/ProviderSlotConfig/GoogleAds';
import { Config } from 'BaxterScript/types/Config';
import { Callbacks, InterstitialSlot, Slot } from 'BaxterScript/types/Slot';
import * as State from 'BaxterScript/version/web/core/State';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { convertMinutesToMilliseconds } from 'BaxterScript/helper/time/TimeConvert';
import { Features } from 'BaxterScript/version/web/config/Features';
import ninjaMetrics from 'BaxterScript/helper/metrics/NinjaMetrics';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { Debounce } from 'BaxterScript/helper/event/Debounce';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { LifecycleQueue } from 'BaxterScript/helper/queue/LifecycleQueue';
import { Cmd } from 'BaxterScript/helper/queue/Queue';

export const id = Features.INTERSTITIAL;
const BAXTER_TOP_LEVEL_DIV_ID = 'baxter-top-level';
const MODAL_CLASS_NAME = 'baxter-interstitial-modal';

const targetPages = {
  home: 'homepage',
  listing: 'listing',
  advertPage: 'ad',
  ad: 'ad',
  ads: 'ads',
  account: 'account',
  adding: 'adding',
  bundles: 'bundles',
  myaccount: 'myaccount',
  pro: 'pro',
  page1: 'page1',
  page2: 'page2',
};

const lifecycleQueue = new LifecycleQueue('INTERSTITIAL');

export const webpackExclude = (config: Config) => {
  const providerSettings = (config.slots?.providerSettings?.[Providers.GOOGLE_ADS] ?? {}) as GoogleAdsConfig;
  const interstitialSettings = providerSettings.interstitial;
  return !(
    (interstitialSettings?._ && Object.values(interstitialSettings._).some((item) => item?.enabled === true)) ||
    (interstitialSettings && Object.values(interstitialSettings).some((item) => item?.enabled === true))
  );
};

const createTopLevelDiv = () => {
  if (!Html.getElementById(BAXTER_TOP_LEVEL_DIV_ID)) {
    console.debug('[SLOTS][INTERSTITIAL][CREATETOPLEVELDIV]', BAXTER_TOP_LEVEL_DIV_ID);
    const div = document.createElement('div');
    div.setAttribute('id', BAXTER_TOP_LEVEL_DIV_ID);
    div.setAttribute('style', 'display: fixed;');
    document.body.appendChild(div);
  }
};

const removeModal = (slot: InterstitialSlot) => {
  console.info('[SLOTS][INTERSTITIAL][REMOVEMODAL]', slot);
  if (slot[id].state.interval) {
    clearInterval(slot[id].state.interval);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.interval = undefined;
  }
  if (slot[id].state.timeout) {
    clearTimeout(slot[id].state.timeout);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.timeout = undefined;
  }
  document.body.classList.remove('baxter-interstitial-overflow-hidden');
  if (slot[id].state.modal) {
    slot[id].state.modal.remove();
    // eslint-disable-next-line no-param-reassign
    slot[id].state.modal = undefined;
  }
  State.removeInterstitialSlot(slot[id].state.slotKey as string);
  Provider.remove([slot], false);
};

const closeModal = (slot: InterstitialSlot) => {
  console.info('[SLOTS][INTERSTITIAL][CLOSEMODAL]', slot);
  newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_CLOSE_MODAL);
  removeModal(slot);
  setTimeout(async () => {
    try {
      await lifecycleQueue.process();
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][CLOSEMODAL][TIMEOUT]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_CLOSE_MODAL_TIMEOUT_ERROR, {
        message: (e as Error).message,
      });
    }
  }, 1);
};

const validSize = (modal: HTMLElement) => {
  const height =
    modal.getElementsByClassName('baxter-interstitial-modal-content')[0].getBoundingClientRect().height +
    modal.getElementsByClassName('baxter-interstitial-modal-footer')[0].getBoundingClientRect().height;
  console.log(height);
  const windowHeight = window.innerHeight;
  const isValidSize = height <= windowHeight;
  if (!isValidSize) {
    console.debug('[SLOTS][INTERSTITIAL][VALIDSIZE] invalid size', height, windowHeight);
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_INVALID_SIZE, { height, windowHeight });
  } else {
    console.debug('[SLOTS][INTERSTITIAL][VALIDSIZE] valid size', height, windowHeight);
  }
  return isValidSize;
};

const closeModalWithInvalidSize = () => {
  Object.values(State.getInterstitialSlots()).forEach((slot) => {
    if (slot[id].state.modal && !validSize(slot[id].state.modal)) {
      closeModal(slot);
    }
  });
};

const clearModal = () => {
  console.info('[SLOTS][INTERSTITIAL][CLEARMODAL]', State.getInterstitialSlots());
  let cleared = false;
  Object.values(State.getInterstitialSlots()).forEach((slot) => {
    if (slot[id].state.modal) {
      cleared = true;
      removeModal(slot);
    }
  });
  lifecycleQueue.removeAll();
  if (cleared) {
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_CLEAR_MODAL);
  }
};

export const bootstrap = () => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      try {
        console.info('[SLOTS][INTERSTITIAL][BOOTSTRAP][DOMCONTENTLOADEDEVENTLISTENER]');
        createTopLevelDiv();
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][BOOTSTRAP][DOMCONTENTLOADEDEVENTLISTENER]', e);
        newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_BOOTSTRAP_LISTENER_ERROR, {
          message: (e as Error).message,
        });
      }
    });
  } else {
    console.info('[SLOTS][INTERSTITIAL][BOOTSTRAP]');
    createTopLevelDiv();
  }
  window.addEventListener(
    'resize',
    Debounce(async () => {
      try {
        console.info('[SLOTS][INTERSTITIAL][RESIZE]');
        closeModalWithInvalidSize();
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][RESIZE]', e);
        newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_RESIZE_LISTENER_ERROR, {
          message: (e as Error).message,
        });
      }
    }, 300)
  );
  window.addEventListener('popstate', async () => {
    try {
      console.info('[SLOTS][INTERSTITIAL][POPSTATE]');
      clearModal();
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][POPSTATE]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_POPSTATE_LISTENER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
};

const getLocalStorageKey = (slotKey: string) => `interstitial_ad_${slotKey}`;

const validFrequencyCap = (slot: InterstitialSlot, slotKey: string) => {
  const frequencyCapInMs = convertMinutesToMilliseconds(slot[id].config.frequencyCap || 1);
  const lastRendering = Number(localStorage.getItem(getLocalStorageKey(slotKey))) || 0;
  const now = Date.now();
  const frequencyCap = lastRendering + frequencyCapInMs;
  const isValidFrequencyCap = Date.now() > frequencyCap;
  if (!isValidFrequencyCap) {
    console.debug(
      '[SLOTS][INTERSTITIAL][VALIDFREQUENCYCAP] invalid frequency cap',
      new Date(now),
      new Date(frequencyCap)
    );
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_INVALID_FREQUENCY_CAP);
  } else {
    console.debug(
      '[SLOTS][INTERSTITIAL][VALIDFREQUENCYCAP] valid frequency cap',
      new Date(now),
      new Date(frequencyCap)
    );
  }
  return isValidFrequencyCap;
};

const getSlotKey = (pageId: string, targetPage: string) => `${pageId}#${targetPage}`;

export const apply = (slot: Slot, callbacks: Callbacks) => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[Providers.GOOGLE_ADS] ||
    {}) as GoogleAdsConfig;
  const config = (getConfigById(providerSettings.interstitial, slot.pageId, slot.containerId, slot.id) ||
    {}) as GoogleAdsInterstitialConfig;
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config,
    state: {
      callbacks,
    },
  };
  if (!slot[id].config.enabled) {
    return false;
  }
  const slotKey = getSlotKey(slot.pageId, slot[id].config.targetPage);
  if (!validFrequencyCap(slot as InterstitialSlot, slotKey)) {
    return true;
  }
  console.debug('[SLOTS][INTERSTITIAL][APPLY] store in state', slot);
  // eslint-disable-next-line no-param-reassign
  slot[id].state.slotKey = slotKey;
  State.setInterstitialSlot(slotKey, slot as InterstitialSlot);
  return true;
};

const slotModalContainerId = (innerId: string) => `${innerId}-modal-container`;

const createModalDiv = (slot: InterstitialSlot) => {
  const modal = document.createElement('div');
  modal.setAttribute('id', slotModalContainerId(slot.innerId));
  modal.setAttribute('style', 'display:none');
  modal.setAttribute('class', 'baxter-interstitial');
  modal.innerHTML = `<div class="${MODAL_CLASS_NAME}">
        <div id="baxter-interstitial-modal-content" class="baxter-interstitial-modal-content"></div>
        <div class="baxter-interstitial-modal-footer">
            <div class="baxter-interstitial-modal-footer-content">
                <div class="baxter-interstitial-modal-footer-content-title">
                    ${slot[id].config.modalTitle}
                </div>
                <small class="baxter-interstitial-modal-footer-content-subtitle${slot[id].config.autoClose ? '' : '--hidden'}">
                    ${slot[id].config.autoCloseText}&nbsp;
                    <span id="baxter-interstitial-modal-footer-countdown-${slot.innerId}"></span>
                </small>
            </div>
            <button class="baxter-interstitial-modal-footer-close-btn">
                <span class="baxter-interstitial-modal-footer-close-btn-title">
                    ${slot[id].config.closeButtonTitle}&nbsp;
                    <span class="baxter-interstitial-modal-footer-close-btn-title-icon">&times;</span>
                </span>
            </button>
        </div>
    </div>`;

  modal.getElementsByClassName('baxter-interstitial-modal-footer-close-btn')?.[0].addEventListener('click', () => {
    try {
      closeModal(slot);
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][CLOSECLICKHANDLER]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_CLOSE_CLICK_HANDLER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
  return modal;
};

const insertModal = (topLevelDiv: HTMLElement, slot: InterstitialSlot): HTMLElement => {
  const modal = createModalDiv(slot);
  topLevelDiv.appendChild(modal);
  document.getElementById('baxter-interstitial-modal-content')!.appendChild(slot.innerHtmlElement);
  return modal;
};

const showCloseTimer = (slot: InterstitialSlot) => {
  let autoClose = (slot[id].config.autoClose || 1) - 1;
  // eslint-disable-next-line no-param-reassign
  slot[id].state.interval = setInterval(() => {
    try {
      if (autoClose > 0) {
        const element = document.getElementById(`baxter-interstitial-modal-footer-countdown-${slot.innerId}`);
        if (element) {
          element.innerHTML = `${autoClose}s`;
        }
        autoClose -= 1;
      }
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][SHOWCLOSETIMER]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_SHOW_CLOSE_TIMER_ERROR, { message: (e as Error).message });
    }
  }, 1000);
};

const setAutoClose = (slot: InterstitialSlot) => {
  if (slot[id].config.autoClose) {
    const autoCloseInMs = slot[id].config.autoClose * 1000;
    console.debug('[SLOTS][INTERSTITIAL][SETAUTOCLOSE]', autoCloseInMs);
    showCloseTimer(slot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.timeout = setTimeout(() => {
      try {
        closeModal(slot);
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][SETAUTOCLOSE][TIMEOUT]', e);
        newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_SET_AUTO_CLOSE_TIMEOUT_ERROR, {
          message: (e as Error).message,
        });
      }
    }, autoCloseInMs);
  }
};

export const delayPageChange = (pageId: string, cmd: Cmd, prevPageId?: string): boolean => {
  if (!prevPageId) {
    console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] not requesting because missing prev page id');
    return false;
  }
  const targetPage = targetPages[pageId.substring(0, pageId.indexOf('-'))] || pageId;
  const slotKey = getSlotKey(prevPageId, targetPage);
  const interstitialSlot = State.getInterstitialSlot(slotKey);
  if (!interstitialSlot) {
    console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] not requesting because missing interstitial slot ', slotKey);
    return false;
  }
  if (interstitialSlot[id].state.modal) {
    console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] not requesting because interstitial already created');
    lifecycleQueue.delayPageChange(cmd, true);
    return true;
  }
  const topLevelDiv = Html.getElementById(BAXTER_TOP_LEVEL_DIV_ID);
  if (!topLevelDiv) {
    console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] not requesting because missing top level div');
    newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_MISSING_TOP_LEVEL_DIV);
    return false;
  }
  if (!validFrequencyCap(interstitialSlot, slotKey)) {
    console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] not requesting because invalid frequency cap');
    return false;
  }
  try {
    interstitialSlot[id].state.modal = insertModal(topLevelDiv, interstitialSlot);
    lifecycleQueue.delayPageChange(cmd, true);
    document.body.classList.add('baxter-interstitial-overflow-hidden');
    const created = Provider.create(interstitialSlot, {
      impressionViewableCallback: (slotId: string, source: string, ninjaParameters: Record<string, unknown>) => {
        ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_VIEWABLE, ninjaParameters);
        return interstitialSlot[id].state.callbacks.impressionViewableCallback(slotId, source, ninjaParameters);
      },
      slotRenderEndedCallback: (
        source: string,
        slot: Slot,
        isEmpty: boolean,
        hasVideo?: boolean,
        ninjaParameters?: Record<string, unknown>
      ) => {
        if (isEmpty) {
          console.debug('[SLOTS][INTERSTITIAL][SLOTRENDERENDEDCALLBACK] no ad to show', slotKey, interstitialSlot);
          closeModal(interstitialSlot);
        } else {
          console.debug('[SLOTS][INTERSTITIAL][SLOTRENDERENDEDCALLBACK] showing modal', slotKey, interstitialSlot);
          newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_SHOW_MODAL);
          ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_IMPRESSION, ninjaParameters);
          interstitialSlot[id].state.modal?.style?.removeProperty?.('display');
          setAutoClose(interstitialSlot);
        }
        return interstitialSlot[id].state.callbacks.slotRenderEndedCallback(
          source,
          slot,
          isEmpty,
          hasVideo,
          ninjaParameters
        );
      },
      slotRequestedCallback: (ninjaParameters) => {
        ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_ADREQUEST_SENT, ninjaParameters);
      },
      slotResponseReceivedCallback: (ninjaParameters) => {
        ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_ADREQUEST_RECEIVED, ninjaParameters);
      },
    });
    if (!created) {
      console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] slot creation failed');
      closeModal(interstitialSlot);
      return false;
    }
    localStorage.setItem(getLocalStorageKey(slotKey), Date.now().toString());
    console.debug('[SLOTS][INTERSTITIAL][REQUESTADS] requesting ads');
    // not awaiting on purpose to not make this method async
    Provider.load(id, [interstitialSlot]);
    return true;
  } catch (e) {
    console.error('[SLOTS][INTERSTITIAL][REQUESTADS]', e);
    newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_SHOW_MODAL_ERROR, { message: (e as Error).message });
    closeModal(interstitialSlot);
    return false;
  }
};

export const delaySet = (cmd: Cmd) =>
  lifecycleQueue.delaySet(
    cmd,
    !!Object.values(State.getInterstitialSlots()).filter((slot) => slot[id].state.modal).length
  );

export const delaySetSpecificContainers = (cmd: Cmd) =>
  lifecycleQueue.delaySetSpecificContainers(
    cmd,
    !!Object.values(State.getInterstitialSlots()).filter((slot) => slot[id].state.modal).length
  );
